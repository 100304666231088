<template>
    <div>Listado de atenciones activas</div>
</template>

<script>
export default {

}
</script>

<style>

</style>